import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './style.module.css';
import matchedV from '../../../../assets/games-imgs/hatamot/matchedV.png';

import { DropTarget, DragDropContainer } from 'react-drag-drop-container';
import TopBar from './TopBar';
import HatamotSuccess from '../../../modals/HatamotSuccess';
import { useHistory } from 'react-router-dom';

const AMOUNT_CARDS = 9; //front cards 

const initFrontCards = (cardsAmount) => {
    const cards = [];
    const timeout = 200;
    let counter = 1;

    while(cards.length !== cardsAmount && counter < timeout){
        const id = Math.floor(Math.random()*AMOUNT_CARDS)+1 + "";
        if(!cards.some(card => card.id === id)){
            cards.push({id, isDraggable: true})
        }
        counter++;
    }

    return cards;
}


const getFormattedId = id => { // ex: gets "10_2" -> return "10"
    if(id.length === 4){
        return "10";
    }
    else if(id.length === 1 || id.length === 2){
        return id;
    }
    else{
        return id.slice(0,1);
    }

}
const initBackCards = (frontCards) => {
    const cards = [];

    frontCards.forEach(frontCard => {
        const id = getFormattedId(frontCard.id);
        cards.push({id});
    })

    cards.sort(() => (Math.random() > .5) ? 1 : -1);
    return cards;
}

export const INIT_CARDS_AMOUNT = 3;

const Hatamot = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);
    const [cardsAmount, setCardsAmount] = useState(INIT_CARDS_AMOUNT);
    const [frontCards, setFrontCards] = useState(() => initFrontCards(cardsAmount));
    const [backCards, setBackCards] = useState(() => initBackCards(frontCards));
    const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
    const mounted = useRef();

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Games' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Games' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'orange' && dispatch({ type: "CHANGE_BG_COLOR", value: 'orange' })
    },[headerColor])

    useEffect(() => { //prevent componentDidMount (just update when 'cardsAmount' changed!)
        if(!mounted.current){
            mounted.current = true;
        }
        else{
            const newFrontCards = initFrontCards(cardsAmount);
            setFrontCards(() => newFrontCards);
            setBackCards(() => initBackCards(newFrontCards))
        }
    },[cardsAmount])

    const isWinner = () => {
        return !frontCards.some(frontCard => frontCard.isDraggable)
    }

    useEffect(() => {
        isWinner() && setSuccessModalIsOpen(true);
    },[frontCards])

    const setIsDraggable = id => {
        const newFrontCards = [];
        frontCards.forEach(frontCard => {
            if(frontCard.id === id){
                newFrontCards.push({id, isDraggable: false})
            }
            else{
                newFrontCards.push({...frontCard})
            }
        })
        setFrontCards(newFrontCards);
    }

    const handleDropped = e => {
        setIsDraggable(e.dragData.id);
    }

    return (
        <div className={style.container}>
            {/* <TopBar /> */}
            <div className={style.successModal}>
                <HatamotSuccess modalIsOpen={successModalIsOpen}
                                setModalIsOpen={setSuccessModalIsOpen}
                                setCardsAmount={setCardsAmount}    
                                level={cardsAmount-INIT_CARDS_AMOUNT+1}    
                />
            </div>
            <div className={style.cards}>
                <div className={style.backCards}>
                    {
                        backCards.map((backCard, idx) => {
                            return(
                                <React.Fragment key={backCard.id + idx}>
                                    <DropTarget targetKey={backCard.id} onHit={handleDropped}>
                                        <div className={style.image}>
                                            <img alt='' width='100%' draggable={false}
                                            style={{boxShadow: "0vw .1vw .7vh rgba(0,0,0,.3)"}}
                                            src={require(`../../../../assets/games-imgs/hatamot/back/${backCard.id}.jpg`).default} />
                                        </div>
                                    </DropTarget>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                <div className={style.frontCards}>
                    {
                        frontCards.map((frontCard => {
                            return (
                                <React.Fragment key={frontCard.id}>
                                    {
                                        frontCard.isDraggable
                                        ?
                                            <DragDropContainer targetKey={getFormattedId(frontCard.id)}
                                                                noDragging={!frontCard.isDraggable}
                                                                dragData={{id: frontCard.id}}
                                                                >
                                                <div className={style.image}>
                                                    <img width='100%' alt='' 
                                                        style={{boxShadow: "0vw .1vw .7vh rgba(0,0,0,.3)"}}
                                                        src={require(`../../../../assets/games-imgs/hatamot/front/${frontCard.id}.jpg`).default} />
                                                </div>
                                            </DragDropContainer>
                                        :
                                            <div className={style.image}>
                                                <img alt='' src={matchedV} width='100%' />
                                            </div>
                                    }
                                </React.Fragment>
                            )
                        }))
                    }
                </div>
            </div>
        </div>
    )
}

export default Hatamot
