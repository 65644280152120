import React, { useEffect, useState, useRef} from 'react';
import useInterval from '../../../../../hooks/useInterval';
import style from './style.module.css';

const TopBar = ({winner, cardsAmount}) => {
    const [level, setLevel] = useState(1);
    const [time, setTime] = useState(0);
    const mounted = useRef();

    useInterval(() => {
        setTime(prev => prev + 1);
    },1000);

    useEffect(() => {
        if (!mounted.current){
            mounted.current = true;
        } 
        else{
            level === 4 ? setLevel(1) : setLevel(level + 1);
            setTime(0);
        }
    },[cardsAmount])

    return (
        <div className={style.topBar}>
            <div className={style.level}>
                <label>דרגה:</label>
                <label>{` ${level}`}</label>
            </div>
            <div className={style.time}>
                <label>זמן:</label>
                <label>{` ${time}`}</label>
            </div>
        </div>
    )
}

export default TopBar
