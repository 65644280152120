import React, { useState, useRef, useEffect } from 'react'
import style from './style.module.css';
import play from '../../../../assets/greenPlayBtn.svg'
import pause from '../../../../assets/greenPauseBtn.svg'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useInterval from '../../../../hooks/useInterval';
import ReactPlayer from 'react-player';

const INITIAL_TIME = '00:00';

const Song = ({song, playerRef, setPauseOthers}) => {
    const [currentTime, setCurrentTime] = useState(INITIAL_TIME);
    const [isPlaying, setIsPlaying] = useState(false);
    
    const formatTime = (time) => {
        // Hours, minutes and seconds
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;
      
        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + String(mins).padStart(2, '0') + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    useInterval(() => {
        if(playerRef.current && isPlaying){
            setCurrentTime(formatTime(Math.floor(playerRef.current.currentTime)));
        }
    },1000)
      
    const playAudio = () => {
        if(playerRef.current){
            if(isPlaying){
                setIsPlaying(false);
                playerRef.current.pause();
            }
            else{
                setPauseOthers();
                setIsPlaying(true);
                playerRef.current.play();
            }
        }
    }

    const handlePause = e => {
        setIsPlaying(false);
    }

    const handleEnded = () => {
        setCurrentTime(INITIAL_TIME);
        setIsPlaying(false);
    }
    return (
        <div className={style.song}>
            <div className={style.window}>
                <div className={style.bgImage}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../../assets/bg/music-bg/${song.id}.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{backgroundColor: '#FFF'}}
                    />
                </div>
                <audio src={require(`../../../../assets/music/${song.id}.mp3`).default}
                        ref={playerRef}
                        onPause={handlePause}
                        onEnded={handleEnded}
                        config={{ file: { 
                            attributes: {
                                controlsList: 'nodownload'  //<- this is the important bit
                            }
                        }}}
                    />
                <div className={style.controlBtn} onClick={playAudio}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={isPlaying ? pause : play}
                        width="100%"
                        draggable={false}
                    />
                </div>
            </div>
            <div className={style.name}>{song.name}</div>
            <div className={style.time}>
            {
                currentTime === '00:00'
                ?
                    song.time
                :
                    `${currentTime}/${song.time}`
                    
            }
            </div>

        </div>
    )
}

export default Song
