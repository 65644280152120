import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
// import { Document, Page } from 'react-pdf/dist/esm/entry.parcel';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useMediaQuery } from 'react-responsive';
import pdfFile from '../../../assets/terms.pdf'
import style from './style.module.css'

const PdfTerms = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const [numPages, setNumPages] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);
    const isMobile = useMediaQuery({ query: `(max-width: 580px)` });
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    }

    return (
      <div className={style.container}>
        <Document
          className={style.doc}
          loading="...טוען תקנון"
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          width={1100}
          scale={1.0}
        >
          <Page pageNumber={pageNumber} width={isMobile ? 500 : 1100} />
          <div className={style.pagging}>
            <p onClick={pageNumber < numPages ? () => setPageNumber(prev => prev + 1) : null} style={{fontWeight: 800, opacity: pageNumber < numPages ? 1 : .4}}>{'<'}</p>
            <p>{numPages} עמוד {pageNumber} מתוך</p>
            <p onClick={pageNumber > 1 ? () => setPageNumber(prev => prev - 1) : null} style={{fontWeight: 800, opacity: pageNumber > 1 ? 1 : .4}}>{'>'}</p>

          </div>
        </Document>
      </div>
    );
}

export default PdfTerms