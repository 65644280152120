import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CodeInput from './CodeInput';
import style from './style.module.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import bigGift from '../../assets/bg/bigGift.png';
import crownBoy from '../../assets/bg/crownBoy.png';
import { verifyKitCodeResp } from '../../api/api';
import { useHistory } from 'react-router-dom';

import PulseLoader from "react-spinners/PulseLoader";
import useLocalStorage from '../../hooks/useLocalStorage';
import { useMediaQuery } from 'react-responsive';

const KitCode = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const activeScreen = useSelector((state) => state.activeScreen);
    const userToken = useSelector((state) => state.userToken);
    const childName = useSelector((state) => state.childName);
    // const [token, setToken] = useLocalStorage('token', '')

    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [code5, setCode5] = useState('');
    const [code6, setCode6] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 580px)` });

    useEffect(() => {
        activeScreen !== 'KitCode' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'KitCode' })
    },[activeScreen])

    const handleClick = async() => {
        if(!code1.trim() || !code2.trim() || !code3.trim()
                || !code4.trim() || !code5.trim() || !code6.trim()){
            setErrorMsg('יש למלא את כל השדות!')
            return;
        }

        errorMsg && setErrorMsg('');
        setIsLoading(true);
        const code = code1 + code2 + code3 + code4 + code5 + code6;
        const data = { code }
        if(childName){
            data.reason = "child";
        }
        const resp = await verifyKitCodeResp(data, userToken, setIsLoading, setErrorMsg)

        if(resp && !resp.code){
            if(isMobile){
                dispatch({ type: "SET_SUCCESS_MOBILE_REG", value: true })
                dispatch({ type: "SET_ACTIVE_SCREEN", value: 'MainScreen' })
                history.push('/');
            }
            else{
                // setToken(userToken);
                localStorage.setItem('token', userToken);
                localStorage.setItem('childName', childName);
                console.log("verifyKitCodeResp > resp:",resp)
    
                dispatch({ type: "SET_ACTIVE_SCREEN", value: 'ContentButtons' })
                dispatch({ type: "SET_IS_CONNECTED", value: true })
                history.push('/content-buttons')
            }
        }
        else{
            setErrorMsg('שגיאה! אחד מהפרטים אינו תקין.');
        }

        setIsLoading(false);
        console.log('verifyKitCodeResp:', resp)
    }

    return (
        <div className={style.container}>
            <div className={style.title}>הזנ/י את הקוד המצורף לערכה שלך</div>
            <CodeInput 
                setCode1={setCode1} code1={code1}
                setCode2={setCode2} code2={code2}
                setCode3={setCode3} code3={code3}
                setCode4={setCode4} code4={code4}
                setCode5={setCode5} code5={code5}
                setCode6={setCode6} code6={code6}
            />
            <div className={style.checkCodeBtn} onClick={isLoading ? null : handleClick}>בדיקת קוד</div>
            {errorMsg ? <div className={style.errorMsg}>{errorMsg}</div> : null}

            <div className={isLoading ? style.spinner : null}>
                <PulseLoader color='rgba(0,0,0,.2)' loading={isLoading} size={isMobile ? '18px' : '.8vw'} />
            </div>

            <div className={style.leftBgImage}>
                <LazyLoadImage
                effect="blur"
                alt={""}
                src={bigGift}
                width="100%"
                draggable={false}
                />
            </div>

            <div className={style.rightBgImage}>
                <LazyLoadImage
                effect="blur"
                alt={""}
                src={crownBoy}
                width="100%"
                draggable={false}
                />
            </div>
        </div>
    )
}

export default KitCode
