import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import { RiArrowDropDownFill } from "react-icons/ri";
import ILFlag from '../../../assets/flag-imgs/il.jpg'
import USFlag from '../../../assets/flag-imgs/us.jpg'
import UKFlag from '../../../assets/flag-imgs/uk.jpg'
import FRFlag from '../../../assets/flag-imgs/fr.jpg'

const options = [
    { value: 'il', label: <img alt="Israel" src={ILFlag} width="100%" /*height="100%" style={{margin: 0, padding: 0}}*/ /> },
    { value: 'us', label: <img alt="USA" src={USFlag} width="100%" /*height="100%" style={{margin: 0, padding: 0}}*/ /> },
    { value: 'uk', label: <img alt="UK" src={UKFlag} width="100%" /*height="100%" style={{margin: 0, padding: 0}}*/ /> },
    { value: 'fr', label: <img alt="FR" src={FRFlag} width="100%" /*height="100%" style={{margin: 0, padding: 0}}*/ /> },
];

const FlagsDropDown = ({ onChange, selectedValue }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={style.container} onClick={() => setVisible(!visible)}>
      {visible ? (
        <div className={style.options}>
          {options.map((op, index) => {
            return <div key={`${op.value}-${index}`} onClick={() => onChange(op.value)} className={style.option}>{op.label}</div>;
          })}
        </div>
      ) : null}

      <div className={style.selectedValue}>{options.find(op => op.value === selectedValue).label}</div>
      <div className={style.arrowIcon}>
        <RiArrowDropDownFill size="100%" />
      </div>
    </div>
  );
};

export default FlagsDropDown;
