import React,{ useEffect, useState } from 'react';
import style from './style.module.css';
import ValidSMS from '../modals/ValidSMS';
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from 'formik';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import picnic from '../../assets/bg/picnic.png';
import { regParentResp } from '../../api/api';
import useLocalStorage from '../../hooks/useLocalStorage';

import PulseLoader from "react-spinners/PulseLoader";
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ReactCountryFlag from "react-country-flag"
import FlagsDropDown from '../modals/FlagsDropDown';

const validate = values => {
    let errors = {};
    
    if(!values.name.trim()){
        errors.name = 'שדה ריק!'
    }

    else if(values.name.length > 20 && !/^[א-ת\s]+$/.test(values.name.trim())){
        errors.name = 'שדה לא תקין!'
    }

    if(!values.phone){
        errors.phone = 'שדה ריק!'
    }
    // else if(!/^5[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/.test(values.phone)){
    //     errors.phone = 'מספר לא תקין!'
    // }

    if(!values.address.trim()){
        errors.address = 'שדה ריק!'
    }
    // else if(!/^[0-9א-ת\,\s]$/.test(values.address)){
    //     errors.address = 'ישנם תווים לא תקינים'
    // }
    else if(values.address.length > 50){
        errors.address = 'חריגה מכמות התווים (50)'
    }
    
    if(!values.email){
        errors.email = 'שדה ריק!'
    }
    // else if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(errors.email)){
    //     errors.email = 'מייל לא תקין!'
    // }
    
    else if(!/(.+)@(.+){2,}\.(.+){2,}/.test(values.email) ){
        errors.email = 'מייל לא תקין!'
    }
    if(!values.terms){
        errors.terms = 'יש לאשר תנאי שימוש!'
    }

    return errors;
}

const Registration = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [validSMSIsOpen, setValidSMSIsOpen] = useState(false);
    const activeScreen = useSelector((state) => state.activeScreen);
    const headerColor = useSelector((state) => state.headerColor);
    const [userInfo, setUserInfo] = useLocalStorage('userInfo', {})
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 580px)` });
    const [selectedFlag, setSelectedFlag] = useState('il')

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Registration' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Registration' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'yellow' && dispatch({ type: "CHANGE_BG_COLOR", value: 'yellow' })
    },[headerColor])

    const initialValues = {
        name: '',
        phone: '',
        email: '',
        address: '',
        updates: false
    }

    const onSubmit = async values => {
        console.log("values:", values)
        setIsLoading(true);
        errorMsg && setErrorMsg('');

        const {terms, ...data} = values; 
        data.updates = values.updates ? "1" : "0";
        const phone = data.phone;
        data.phone = getCodeArea() + phone; 
        console.log("data:", data)

        setUserInfo(data); // update localstorage
        dispatch({ type: "UPDATE_USER_INFO", value: data }) // update redux store
        const resp = await regParentResp(data);

        if(resp && !resp.code){
            setValidSMSIsOpen(true);
        }
        else if(resp && (resp.error === 'PARENT_EXISTS' || resp.error === 'PARENT_EXISTS_WITH_KITCODE')){
            setErrorMsg('שגיאה! משתמש קיים.');
        }
        else{
            setErrorMsg('שגיאה! אחד מהפרטים אינו תקין.')
        }
        
        setIsLoading(false);
        console.log('regParentResp:', resp)
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })


    const gotoTermsScreen = () => {
        window.open(window.location.origin + '/#/terms', "_blank");
        // dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Terms' })
        // history.push('/terms')
    }

    const getCodeArea = () => {
        switch(selectedFlag){
            case "il":
                return ""
            case "us":
                return "+1"
            case "uk":
                return "+44"
            case "fr":
                return "+33"
        }
    }

    return (
        <div className={style.container}>
            <div className={style.regForm}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={style.title}>היי, ברוכים הבאים</div>
                    <div className={style.subtitle}>היכרות קצרה ומתחילים בחוויה</div>
                    <div className={style.topInputs}>
                        <div className={style.topInput}>
                            <label htmlFor='name'>שם מלא</label>
                            <input type='text' id='name' name='name' 
                                placeholder='הקלד/י...' 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                maxLength={20}
                                style={{border: formik.errors.name && formik.touched.name ? '.2vh solid #FF0000' : ''}}
                                />
                            {/* {formik.errors.name && formik.touched.name ? <div className={style.errorMsg}>{formik.errors.name}</div> : null} */}
                        </div>
                        <div className={style.topInput}>
                            <label htmlFor='phone'>טלפון</label>
                            <div className={style.phoneInputs}>
                                <input type='text' id='phone' name='phone'
                                    placeholder='הקלד/י...'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                    style={{border: formik.errors.phone && formik.touched.phone ? '.2vh solid #FF0000' : ''}}
                                    />
                                <div className={style.phoneAreaCode}>
                                    <FlagsDropDown 
                                        onChange={flag => setSelectedFlag(flag)}
                                        selectedValue={selectedFlag}
                                    />
                                </div>
                            </div>
                            {/* {formik.errors.phone && formik.touched.phone ? <div className={style.errorMsg}>{formik.errors.phone}</div> : null} */}
                        </div>
                        <div className={style.topInput}>
                            <label htmlFor='address'>כתובת</label>
                            <input type='text' id='address' name='address'
                                placeholder='הקלד/י...'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.address}
                                maxLength={50}
                                style={{border: formik.errors.address && formik.touched.address ? '.2vh solid #FF0000' : ''}}
                                />
                            {/* {formik.errors.address && formik.touched.address ? <div className={style.errorMsg}>{formik.errors.address}</div> : null} */}
                        </div>
                        <div className={style.topInput}>
                            <label htmlFor='email'>מייל</label>
                            <input type='email' id='email' name='email'
                                placeholder='הקלד/י...'
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                style={{border: formik.errors.email && formik.touched.email ? '.2vh solid #FF0000' : ''}}
                                />
                            {/* {formik.errors.email && formik.touched.email ? <div className={style.errorMsg}>{formik.errors.email}</div> : null} */}
                        </div>
                    </div>
                    <div className={style.checkboxes}>
                        <div className={style.checkbox}>
                            <div className={style.square}>
                                <input type='checkbox' id='updates' name='updates'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    // value={formik.values.updates}
                                    />
                            </div>
                            <label>אשמח לקבל עדכונים על מבצעים והטבות במייל</label>
                        </div>
                        <div className={style.checkbox} style={{color: formik.errors.terms ? '#FF0000' : ''}}>
                            <div className={style.square}>
                                <input type='checkbox' id='terms' name='terms'
                                    onChange={formik.handleChange}
                                    value={formik.values.terms}
                                />
                            </div>
                            <label>קראתי את <span style={{fontSize: isMobile ? '15px' : ".9vw", cursor: 'pointer', textDecoration: 'underline'}} onClick={gotoTermsScreen}>תנאי השימוש</span> ואני מסכים/ה</label>
                        </div>
                    </div>
                    <div className={style.regBtn} onClick={isLoading ? null : formik.handleSubmit}>הרשמה</div>
                    {errorMsg ? <div className={style.errorMsg} style={{margin: '1vh auto'}}>{errorMsg}</div> : null}
                    <ValidSMS modalIsOpen={validSMSIsOpen} setModalIsOpen={setValidSMSIsOpen}/>
                    <div className={style.spinner}>
                        <PulseLoader color='rgba(0,0,0,.2)' loading={isLoading} size='10px' />
                    </div>
                </form>
            </div>
            <div className={style.bgImage}>
                <LazyLoadImage
                effect="blur"
                alt={""}
                src={picnic}
                width="100%"
                height="100%"
                draggable={false}
                />
            </div>
        </div>
    )
}

export default Registration
