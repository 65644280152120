import React,{useEffect, useRef} from 'react'
import style from './style.module.css';

const CodeInput = ({ setCode1, setCode2, setCode3, setCode4, setCode5, setCode6,
                        code1, code2, code3, code4, code5, code6 }) => {
    const code1Ref = useRef();
    const code2Ref = useRef();
    const code3Ref = useRef();
    const code4Ref = useRef();
    const code5Ref = useRef();
    const code6Ref = useRef();

    useEffect(() => {
        if(code1Ref.current){
            code1Ref.current.focus();
        }
    }, [])
    
    useEffect(() => {
        if(code2Ref.current && code1){
            code2Ref.current.focus();
        }
    }, [code1])
    
    useEffect(() => {
        if(code3Ref.current && code2){
            code3Ref.current.focus();
        }
    }, [code2])

    useEffect(() => {
        if(code4Ref.current && code3){
            code4Ref.current.focus();
        }
    }, [code3])
    useEffect(() => {
        if(code5Ref.current && code4){
            code5Ref.current.focus();
        }
    }, [code4])

    useEffect(() => {
        if(code6Ref.current && code5){
            code6Ref.current.focus();
        }
    }, [code5])
    
    return (
        <div className={style.container}>
                <input type='text' maxLength={1} id='code1' name='code1'
                    onChange={e => setCode1(e.target.value)} ref={code1Ref}
                />
                <input type='text' maxLength={1} id='code2' name='code2'
                    onChange={e => setCode2(e.target.value)} ref={code2Ref}
                />
                <input type='text' maxLength={1} id='code3' name='code3'
                    onChange={e => setCode3(e.target.value)} ref={code3Ref}
                />
                <input type='text' maxLength={1} id='code4' name='code4'
                    onChange={e => setCode4(e.target.value)} ref={code4Ref}
                />
                <input type='text' maxLength={1} id='code5' name='code5'
                    onChange={e => setCode5(e.target.value)} ref={code5Ref}
                />
                <input type='text' maxLength={1} id='code6' name='code6'
                    onChange={e => setCode6(e.target.value)} ref={code6Ref}
                />
        </div>
    )
}

export default CodeInput
