import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import p1 from '../../assets/profile-imgs/1.png'
import p2 from '../../assets/profile-imgs/2.png'
import p3 from '../../assets/profile-imgs/3.png'
import p4 from '../../assets/profile-imgs/4.png'
import bg from '../../assets/bg/account_bg.png'
import { Link } from 'react-router-dom';
import EditUserInfo from '../modals/EditUserInfo';
import { useDispatch, useSelector } from 'react-redux';
import MathExe from '../modals/MathExe';
import { parentDetailsResp, userDetailsResp } from '../../api/api';
import Logout from '../modals/Logout';
import UnregChild from '../modals/UnregChild';
import UnregChildBtn from '../../assets/unregChildBtn.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useHistory } from 'react-router-dom';

const MyAccount = () => {
    const history = useHistory();
    const mathResult = useSelector((state) => state.mathResult);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [logoutModalIsOpen, setLogoutModalIsOpen] = useState(false);
    const [unregChildModalIsOpen, setUnregChildModalIsOpen] = useState(false);
    const [mathModalIsOpen, setMathModalIsOpen] = useState(mathResult ? false : true);
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const userInfo = useSelector((state) => state.userInfo);
    const activeScreen = useSelector((state) => state.activeScreen);
    const userToken = useSelector((state) => state.userToken);
    const childName = useSelector((state) => state.childName);
    const isConnected = useSelector((state) => state.isConnected);
    const [childs, setChilds] = useState([]);
    const [unregChildName, setUnregChildName] = useState([]);
    
    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    // activeScreen !== "MyAccount" && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'MyAccount' })
    useEffect(() => {
        activeScreen !== 'MyAccount' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'MyAccount' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'yellow' && dispatch({ type: "CHANGE_BG_COLOR", value: 'yellow' })
    },[headerColor])

    useEffect(async () => {
        await loadChilds();
    },[])

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    const loadChilds = async () => {
        const detailsResp = childName ? await parentDetailsResp({phone: userInfo.phone}, userToken)
                                      : await userDetailsResp({phone: userInfo.phone}, userToken)
        console.log('detailsResp:::><>', detailsResp);
        if(detailsResp && !detailsResp.code){
            dispatch({type: "UPDATE_USER_INFO", value: detailsResp.data})
            localStorage.setItem("userInfo", JSON.stringify(detailsResp.data))
            if(detailsResp.data && detailsResp.data.children){
                setChilds(detailsResp.data.children);
            }
            else if(detailsResp.data && !detailsResp.data.children){
                childs.length && setChilds([])
            }
        }
    }
    
    const handleUnregChildClick = (name) => {
        setUnregChildName(name);
        setUnregChildModalIsOpen(true);
    }

    console.log('childs:', childs)
    console.log('userToken:', userToken)
    console.log('unregChildName:', unregChildName)
    console.log('userInfo:', userInfo)

    if(!isConnected){
        return null;
    }
    return (
        <div className={style.container}>
            <div className={style.modal}>
                {childName ? null : <MathExe modalIsOpen={mathModalIsOpen} setModalIsOpen={setMathModalIsOpen} />}
                <EditUserInfo modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                <Logout modalIsOpen={logoutModalIsOpen} setModalIsOpen={setLogoutModalIsOpen} />
                <UnregChild modalIsOpen={unregChildModalIsOpen} setModalIsOpen={setUnregChildModalIsOpen}
                            name={unregChildName} loadChilds={loadChilds} />
            </div>

            <img src={bg} alt='' width='30%' draggable={false} style={{position: 'absolute',zIndex: -1, bottom: '15px', left:0}}/>
            <div className={style.profile}>
                <div className={style.profileImg}>
                    <img src={p1} alt='' width='100%' draggable={false} />
                </div>
                <div className={style.profileInfo}>
                    <div className={style.name}>{userInfo.name}</div>
                    <div className={style.phone}>{userInfo.phone}</div>
                    <div className={style.email}>{userInfo.email}</div>
                    <div className={style.address}>{userInfo.address}</div>
                    <div className={style.editInfoBtn} 
                         onClick={childName ? null : () => setModalIsOpen(true)}
                         style={{color: childName ? '#808080' : '#000',
                            border: childName ? '.2vw solid #808080' : '.2vw solid #000'}}
                    >עריכת פרטים</div>
                </div>
            </div>
            {
                childs.length
                ?
                    <div className={style.subUsers}>
                        <div className={style.title}>משתמשים נוספים בחשבון</div>
                        <div className={style.extraProfiles}>
                            {
                                childs.map((child,idx) => {
                                    return(
                                        <div className={style.extraProfile} key={`${child.name}_${idx}`}>
                                            <div className={style.extraProfileImg}>
                                                <LazyLoadImage
                                                    effect="blur"
                                                    alt={""}
                                                    src={p2}
                                                    width="100%"
                                                    draggable={false}
                                                />
                                            </div>
                                            <div className={style.phoneName}>{child.name}</div>
                                            <div className={style.unRegChildBtn} 
                                                    onClick={childName ? null : () => handleUnregChildClick(child.name)}>
                                                <img alt="" src={UnregChildBtn} width='100%' />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                :
                    null
            }
            <div className={style.bottomBtns} style={{marginTop: childs.length ? '22vh' : '42vh'}}>
                <Link to='/terms'>
                    <div className={style.terms}>תקנון ותנאי שימוש ופרטיות</div>
                </Link>
                <div className={style.logout} 
                     onClick={childName ? null : () => setLogoutModalIsOpen(true)}
                     style={{color: childName ? "#808080" : "000"}}
                        >יציאה מהחשבון</div>
            </div>
        </div>
    )
}

export default MyAccount
