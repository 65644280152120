import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import success from '../../../assets/success.png'
const ContactSuccess = ({ modalIsOpen, setModalIsOpen }) => {
    const history = useHistory();

    const handleClick = () => {
        history.push('/');
        setModalIsOpen(false);
    }

    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: 'rgba(0,0,0,.3)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '22%',
                        // height: '40%',
                        backgroundColor: '#FFF',
                        borderRadius: '1vw',
                        outline: 'unset',
                        zIndex: 100,
                    }
                }
            }>
            <div className={style.container}>
                <div className={style.title}>הפרטים נשלחו בהצלחה!</div>
                <div className={style.successImg}>
                    <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={success}
                    width="100%"
                    draggable={false}
                    />
                </div>
                <div className={style.text}>תודה רבה על פנייתך,</div>
                <div className={style.text}>ניצור איתך קשר בהקדם.</div>
                <div className={style.btn} onClick={handleClick}>אישור</div>
            </div>
        </Modal>
    )
}

export default ContactSuccess
