import React, {useEffect, useState} from 'react';
import style from './style.module.css';
import { useDispatch, useSelector} from 'react-redux';
import Card from './Card';
import { v4 as uuidv4 } from 'uuid';
import TopBar from './TopBar';
import { useHistory } from 'react-router-dom';

const Zikaron = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);
    const [cardsAmount, setCardsAmount] = useState(4);
    const [cards, setCards] = useState(null);
    const [firstFlipped, setFirstFlipped] = useState(null);
    const [secondFlipped, setSecondFlipped] = useState(null);

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Games' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Games' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'orange' && dispatch({ type: "CHANGE_BG_COLOR", value: 'orange' })
    },[headerColor])

    useEffect(() => {
        setCards(initCards(4));
    },[])

    useEffect(() => {
        const isMatched = matched();
        if(isMatched){
            updateMatched();
        }
        else if (isMatched === false){
            updateUnMatched();
        }
    })

    useEffect(() => {
        if(winner()){
            let amount = 0;
            setFirstFlipped(null);
            setSecondFlipped(null);
            if(cardsAmount >= 16){
                amount = 4;
                setCardsAmount(4);
            }
            else{
                amount = cardsAmount + 4;
                setCardsAmount(() => amount);
            }
            setCards(initCards(amount));
        }
    },[cards])

    const flip = (card) => {
        const newCards = [...cards]
        const newCard = {...card};
        newCards.forEach(c => {
            if(c.id === newCard.id){
                c.isFlipped = !c.isFlipped;
                setCards(newCards)
                if(firstFlipped){
                    setSecondFlipped(card)
                }
                else{
                    setFirstFlipped(card)
                }
            }
        })
    }

    const winner = () => {
        if(cards){
            return cards.every(card => card.hasMatch);
        }
        return false;
    }

    const updateUnMatched = () => {
        const newCards = [...cards];
        newCards.forEach(card => {
            if(card.id === firstFlipped.id || card.id === secondFlipped.id){
                card.isFlipped = false;
            }
        })
        
        setTimeout(() => {
            setFirstFlipped(null);
            setSecondFlipped(null);
            setCards(newCards);
        },800)

    }

    const updateMatched = () => {
        const newCards = [...cards];
        newCards.forEach(card => {
            if(card.id === firstFlipped.id){
                card.hasMatch = true;
            }
            else if(card.id === secondFlipped.id){
                card.hasMatch = true;
            }
        })

        setCards(newCards);
        setFirstFlipped(null);
        setSecondFlipped(null);
    }

    const matched = () => {
        if(cards && firstFlipped && secondFlipped){
            if(firstFlipped.imgId === secondFlipped.imgId){
                return true;
            }
            return false
        }
        return null;
    }

    const initCards = (amount) => {
        const newCards = [];
        let timeout = 0;

        while(newCards.length !== amount && timeout<150){
            timeout++;
            const imgId = Math.floor((Math.random()*24) + 1) //random number between 1-24
            const alreadySelected = newCards.filter(card => card.imgId === imgId).length;
            if(!alreadySelected){
                newCards.push({id: uuidv4(), imgId, hasMatch: false, isFlipped: false})
                newCards.push({id: uuidv4(), imgId, hasMatch: false, isFlipped: false})
            }
        }
        newCards.sort(() => (Math.random() > .5) ? 1 : -1);
        return newCards;
    }

    console.log('firstFlipped:', firstFlipped)
    console.log('secondFlipped:', secondFlipped)
    console.log('cards:', cards);

    const getBoardBodyWidth = () => {
        if(!cards){
            return "25%";
        }
        if(cards.length === 8){
            return "40%";
        }
        else if(cards.length === 12){
            return "60%";
        }
        else if(cards.length === 16){
            return "80%";
        }
        return "25%"
    }
    return (
        <div className={style.container}>
            <TopBar winner={winner} cardsAmount={cardsAmount} />
            <div className={style.body} style={{width: getBoardBodyWidth()}}>
                <div className={style.cardList}>
                    {
                        cards
                        ?
                            cards.map((card, idx) =>
                                <React.Fragment key={idx}>
                                    <Card 
                                        flip={flip}
                                        card={card}
                                        secondFlipped={secondFlipped}
                                    />
                                </React.Fragment>
                            )
                        :
                            null
                    }
                </div>
            </div>
        </div>
    )
}

export default Zikaron
