import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { unregChildResp } from '../../../api/api';
import PulseLoader from "react-spinners/PulseLoader";

const UnregChild = ({ modalIsOpen, setModalIsOpen, name, loadChilds }) => {
    const userInfo = useSelector((state) => state.userInfo);
    const userToken = useSelector((state) => state.userToken);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleUnregClick = async () => {
        errorMsg && setErrorMsg('')
        setIsLoading(true);

        const { phone } = userInfo; 
        const resp = await unregChildResp({phone, name}, userToken, setIsLoading, setErrorMsg)
        console.log('unregChildResp:',resp);

        if(resp && !resp.code){
            await loadChilds();
            setModalIsOpen(false);
        }
        else{
            setErrorMsg('ישנה שגיאה! נסה/י שוב.')
        }

        setIsLoading(false);

    }

    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: 'rgba(0,0,0,.3)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '22%',
                        height: '25%',
                        backgroundColor: '#FFF',
                        // border: '.3vw solid #FFF',
                        borderRadius: '1vw',
                        outline: 'unset',
                        zIndex: 100,
                    }
                }
            }>
            <div className={style.container}>
                <div className={style.cancle}>
                    <div className={style.closeBtn} onClick={() => setModalIsOpen(false)}>⨉</div>
                </div>
                <div className={style.title}>{`היי ${userInfo.name},`}</div>
                <div className={style.question}>האם את/ה בטוח/ה</div>
                <div className={style.question}>שברצונך לנתק מכשיר זה?</div>
                <div className={style.btn} onClick={handleUnregClick}>נתק מכשיר</div>
                {errorMsg ? <div className={style.errorMsg}>{errorMsg}</div> : null}
                <div className={style.spinner}>
                    <PulseLoader color='rgba(0,0,0,.2)' loading={isLoading} size='.8vw' />
                </div>
            </div>
        </Modal>
    )
}

export default UnregChild
