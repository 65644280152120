import React from 'react'
import style from './style.module.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import bg from '../../assets/app-imgs/bg.png'
import apple from '../../assets/app-imgs/apple.png'
import android from '../../assets/app-imgs/android.png'
import { useSelector } from 'react-redux';

const Mobile = () => {
    const successMobileReg = useSelector((state) => state.successMobileReg);
    
    const handleGoogleClick = () => {
        const link = 'https://play.google.com/store/apps/details?id=il.co.matanot.matanot_new';
        window.open(link);
    }

    const handleAppleClick = () => {
        const link = 'http://onelink.to/matanot';
        window.open(link);
    }

    return (
        <div className={style.container}>

            <div className={style.titleWrapper}>
            {
                successMobileReg
                ?
                    <>
                        <div className={style.title}>תהליך ההרשמה הסתיים !</div>
                        <div className={style.subTitle}>זה הזמן להוריד את האפליקציה מחנויות האפליקציות, להזין את מספר הטלפון איתו נרשמתם ולהנות !</div>
                    </>
                :
                    <>
                        <div className={style.title}>אנו מזמינים אתכם</div>
                        <div className={style.title}>להנות מ"מתנות קטנות" גם באפליקציה</div>
                    </>
            }
            </div>
            <div className={style.mobiles}>
                <div className={style.mobile}>
                    <div className={style.mobTitle}>להורדת האפליקציה באנדרואיד</div>
                    <div className={style.mobImg} onClick={handleGoogleClick}>
                        <LazyLoadImage
                            effect="blur"
                            alt={""}
                            src={android}
                            width="100%"
                            draggable={false}
                        />
                    </div>
                </div>
                <div className={style.mobile}>
                    <div className={style.mobTitle}>להורדת האפליקציה באייפון</div>
                    <div className={style.mobImg} onClick={handleAppleClick}>
                        <LazyLoadImage
                            effect="blur"
                            alt={""}
                            src={apple}
                            width="100%"
                            draggable={false}
                        />
                    </div>
                </div>
            </div>
            <div className={style.bgImage}>
                <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={bg}
                    width="100%"
                    draggable={false}
                />
            </div>
        </div>
    )
}

export default Mobile
