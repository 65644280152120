import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.css';
import { useSvgDrawing } from 'react-hooks-svgdrawing'

import { MdUndo, MdFileDownload } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { IoBrushSharp } from "react-icons/io5";
import { AiOutlineBgColors } from "react-icons/ai"
import { HiPencil } from "react-icons/hi"

import ColorsPicker from '../../../../modals/ColorsPicker';
import ReactSimpleRange from 'react-simple-range';

export const colors = [
    "#ffffff", "#000000", "#212121", "#3e2622", "#dd2c00",
    "#304fff", "#6200e9", "#aa00ff", "#c41162", "#d50100",
    "#01c853", "#01bfa5", "#00b8d4", "#0091ea", "#a22162",
    "#2a62ff", "#ffab00", "#ffd600", "#aeea00", "#64dd16"
]

const DrawingBoard = ({setIsMyDrawings}) => {
    const [choosingPenColor, setChoosingPenColor] = useState(true);
    const [penColor, setPenColor] = useState(colors[1])
    const [bgColor, setBgColor] = useState(colors[0])
    const [onPen, setOnPen] = useState(true);
    const [colorsModalIsOpen, setColorsModalIsOpen] = useState(false);
    const [penWidth, setPenWidth] = useState(5)

    const [renderRef, draw] = useSvgDrawing({
        penWidth: penWidth, // pen width
        penColor: penColor, // pen color
        // close: true, // Use close command for path. Default is false.
        curve: true, // Use curve command for path. Default is true.
        delay: 10, // Set how many ms to draw points every.
        // fill: '#000'// Set fill attribute for path. default is `none`
      })

      useEffect(() => {
        draw.changePenColor(onPen ? penColor : bgColor);
      }, [penColor, onPen, bgColor])

      useEffect(() => {
        draw.changePenWidth(penWidth);
      },[penWidth])

    const handleBrashClick = () => {
        setChoosingPenColor(true);
        setColorsModalIsOpen(true)
    }

    const handleBgClick = () => {
        setChoosingPenColor(false);
        setColorsModalIsOpen(true)
    }

    const handleDownloadClick = () => {
        const canvas = document.getElementById("board");
        const ctx = canvas.getContext("2d"); 

        console.log("canvas:",canvas)
        const url = canvas.toDataURL("image/png");
        const link = document.createElement('a');
        link.download = 'filename.png';
        link.href = url;
        link.click();


        // const ctx = canvas.getContext("2d"); 
        // const img = canvas.toDataURL("image/png");
        // document.write('<img src="'+img+'"/>');
    }

    return (
        <div className={style.container}>
            <div className={style.modals}>
                <ColorsPicker modalIsOpen={colorsModalIsOpen}
                              setModalIsOpen={setColorsModalIsOpen}
                              penColor={penColor}
                              setPenColor={setPenColor}
                              bgColor={bgColor}
                              setBgColor={setBgColor}
                              choosingPenColor={choosingPenColor}
                />
            </div>
            <div className={style.buttons}>
                <button className={style.bg} onClick={handleBgClick}><AiOutlineBgColors color={bgColor}/></button>
                <button className={style.brash} onClick={handleBrashClick}><IoBrushSharp color={penColor}/></button>
                <button className={style.pen} onClick={() => setOnPen(prv => !prv)}><HiPencil style={{transform: `rotate(${onPen ? "0" : "180deg"})`}}/></button>
                <div className={style.slideContainer}>
                    <div className={style.slider}>
                        <ReactSimpleRange min={1} max={30} defaultValue={penWidth}
                                        thumbColor="#000"
                                        trackColor="#000"
                                        sliderSize={7}
                                        onChangeComplete={e => setPenWidth(e.value)}
                        />
                    </div>
                </div>
                <button className={style.undo} onClick={() => draw.undo()}><MdUndo /></button>
                <button className={style.clear} onClick={() => draw.clear()}><IoMdTrash /></button>
                {/* <button className={style.download} onClick={handleDownloadClick}><MdFileDownload /></button> */}
                <button className={style.download} onClick={() => draw.download('png')}><MdFileDownload /></button>
                {/* <button className={style.myDrawings} onClick={() => setIsMyDrawings(true)}>שמורים</button> */}
            </div>
            <div className={style.board} id="board" ref={renderRef}
                 style={{ width: '90%', height: '62vh', 
                          backgroundColor: bgColor,
                 }}

            />
        </div>
    )
}

export default DrawingBoard
