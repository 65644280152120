import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';
import book from '../../assets/contentBtns/book.png';
import clips from '../../assets/contentBtns/clips.png';
import games from '../../assets/contentBtns/games.png';
import music from '../../assets/contentBtns/music.png';
import tv from '../../assets/contentBtns/tv.png';
import style from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { parentDetailsResp } from '../../api/api';
import { useHistory } from 'react-router-dom';


const ContentButtons = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);
    const userToken = useSelector((state) => state.userToken);
    const userInfo = useSelector((state) => state.userInfo);
    const isConnected = useSelector((state) => state.isConnected);

    useEffect(() => { //checks for page refreshing 
        console.log("content-buutons > useEffect")
        console.log("window.performance:", window.performance)
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'ContentButtons' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'ContentButtons' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'yellow' && dispatch({ type: "CHANGE_BG_COLOR", value: 'yellow' })
    },[headerColor])
    
    const loadChildDetails = async () => {
        const detailsResp = await parentDetailsResp({phone: userInfo.phone}, userToken)
        if(detailsResp && !detailsResp.code){
            console.log('detailsResp:::><>', detailsResp);
            if(detailsResp.data && detailsResp.data.children){
                // setChilds(detailsResp.data.children);
            }
            else if(detailsResp.data && !detailsResp.data.children){
                // childs.length && setChilds([])
            }
        }
    }

    if(!isConnected){
        return null;
    }
    return (
    <div className={style.container} 
            style={{marginTop: activeScreen === "ContentButtons" ? '20vh' : 'none'}}>
        {
            activeScreen === 'MainScreen'
            ?
                <div className={style.buttonsContainer}>
                    <div className={style.topButtons}>
                            <div className={style.button}><img src={book} width='100%' draggable={false} alt='' /></div>
                            <div className={style.button}><img src={tv} width='100%' draggable={false} alt='' /></div>
                            <div className={style.button}><img src={music} width='100%' draggable={false} alt='' /></div>
                    </div>
                    <div className={style.bottomButtons}>
                            <div className={style.button}><img src={games} width='100%' draggable={false} alt='' /></div>
                            <div className={style.button}><img src={clips} width='100%' draggable={false} alt='' /></div>
                    </div>
                </div>
            :
                <>
                    <div className={style.title}>לוחצים על כפתור כדי להתחיל</div>
                    <div className={style.buttonsContainer}>
                        <div className={style.topButtons}>
                            <Link to='/content-buttons/book'>
                                <div className={style.button}><img src={book} width='100%' draggable={false} alt='' /></div>
                            </Link>
                            <Link to='/content-buttons/tv'>
                                <div className={style.button}><img src={tv} width='100%' draggable={false} alt='' /></div>
                            </Link>
                            <Link to='/content-buttons/music'>
                                <div className={style.button}><img src={music} width='100%' draggable={false} alt='' /></div>
                            </Link>
                        </div>
                        <div className={style.bottomButtons}>
                            <Link to='/content-buttons/games'>
                                <div className={style.button}><img src={games} width='100%' draggable={false} alt='' /></div>
                            </Link>
                            <Link to='/content-buttons/videos'>
                                <div className={style.button}><img src={clips} width='100%' draggable={false} alt='' /></div>
                            </Link>
                        </div>
                    </div>
                </>
        }
    </div>
    )
}

export default ContentButtons
