import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import style from './style.module.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import oldLovers from '../../assets/contact-imgs/oldLovers.png';
import kite from '../../assets/contact-imgs/kite.png';
import grass from '../../assets/contact-imgs/grass.png';
import { useFormik } from 'formik';
import { contactResp } from '../../api/api';
import PulseLoader from "react-spinners/PulseLoader";
import ContactSuccess from '../modals/ContactSuccess';
import { useHistory } from 'react-router-dom';

const validate = values => {
    let errors = {};
    
    if(!values.name.trim()){
        errors.name = 'שדה ריק!'
    }

    else if(values.name.length > 20 && !/^[א-ת\s]+$/.test(values.name.trim())){
        errors.name = 'שדה לא תקין!'
    }

    if(!values.phone.trim()){
        errors.phone = 'שדה ריק!'
    }
    else if(!/^05[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/.test(values.phone)){
        errors.phone = 'מספר לא תקין!'
    }
    
    if(!values.email){
        errors.email = 'שדה ריק!'
    }

    if(!values.message.trim()){
        errors.message = 'שדה ריק!'
    }

    return errors;
}

const Contact = () => {
    const history = useHistory();
    const dispatch = useDispatch(); 
    const activeScreen = useSelector((state) => state.activeScreen);
    const  [isLoading, setIsLoading] = useState(false);
    const  [errorMsg, setErrorMsg] = useState('');
    const  [contactSuccessModalIsOpen, setContactSuccessModalIsOpen] = useState(false);
    
    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Contact' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Contact' });
    }, [activeScreen])

    const initialValues = {
        name: '',
        phone: '',
        email: '',
        message: '',
        updates: false
    }
    const onSubmit = async values => {
        setIsLoading(true);
        
        errorMsg && setErrorMsg('');
        const data = {...values, updates: values.updates ? "1" : "0"};
        const resp = await contactResp(data, setIsLoading, setErrorMsg);
        if(resp && !resp.code){
            console.log('success')
            formik.resetForm();

            setContactSuccessModalIsOpen(true);
        }        
        else{
            setErrorMsg('שגיאה! אחד מהפרטים אינו תקין.')
        }
        
        setIsLoading(false);
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    return (
        <div className={style.container}>
            <div className={style.modal}>
                <ContactSuccess modalIsOpen={contactSuccessModalIsOpen} setModalIsOpen={setContactSuccessModalIsOpen} />
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className={style.topInputs}>
                    <div className={style.topInput}>
                        <label htmlFor='name'>שם מלא</label>
                        <input type='text' id='name' name='name' 
                            placeholder='הקלד/י...' 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            style={{border: formik.errors.name && formik.touched.name ? '.2vh solid #FF0000' : ''}}
                        />
                    </div>
                    <div className={style.topInput}>
                        <label htmlFor='phone'>טלפון</label>
                        <input type='text' id='phone' name='phone'
                            placeholder='הקלד/י...' 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            style={{border: formik.errors.phone && formik.touched.phone ? '.2vh solid #FF0000' : ''}}
                        />
                    </div>
                    <div className={style.topInput}>
                        <label htmlFor='email'>מייל</label>
                        <input type='text' id='email' name='email'
                            placeholder='הקלד/י...' 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            style={{border: formik.errors.email && formik.touched.email ? '.2vh solid #FF0000' : ''}}
                        />
                    </div>
                </div>
                <div className={style.freeInput}>
                        <label htmlFor='freeText'>איך נוכל לעזור?</label>
                        <textarea type='text' id='message' name='message' placeholder='כתבו בחופשיות...'
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                            style={{border: formik.errors.message && formik.touched.message ? '.2vh solid #FF0000' : ''}}
                        />
                </div>
                <div className={style.updateCheckbox}>
                    <div className={style.square}>
                        <input type='checkbox' name='updates' id='updates'
                            onChange={formik.handleChange} 
                            value={formik.values.updates}
                        />
                    </div>
                    <label>אשמח לקבל עדכונים על מבצעים והטבות במייל</label>
                </div>
                <button className={style.sendBtn} type='submit' disabled={isLoading} onClick={isLoading ? null : formik.handleSubmit}>שליחה</button>
                { errorMsg ? <div className={style.errorMsg}>{errorMsg}</div> : null}
                <div className={style.spinner}>
                    <PulseLoader color='rgba(0,0,0,.2)' loading={isLoading} size='.8vw' />
                </div>
            </form>
            <div className={style.oldLoversBg}>
                <LazyLoadImage
                effect="blur"
                alt={""}
                src={oldLovers}
                width="100%"
                draggable={false}
                />
            </div>
            <div className={style.grassBg}>
                <LazyLoadImage
                effect="blur"
                alt={""}
                src={grass}
                width="100%"
                draggable={false}
                />
            </div>
            <div className={style.kiteBg}>
                <LazyLoadImage
                effect="blur"
                alt={""}
                src={kite}
                width="100%"
                draggable={false}
                />
            </div>
        </div>
    )
}

export default Contact
