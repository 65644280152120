import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';

import { INIT_CARDS_AMOUNT } from '../../ContentButtons/Games/Hatamot' 

const HatamotSuccess = ({ modalIsOpen, setModalIsOpen, setCardsAmount, level }) => {

    const handleNextLevel = () => {
        console.log("INIT_CARDS_AMOUNT:",INIT_CARDS_AMOUNT)
        console.log("level:",level)
        setCardsAmount(level === 3 ? INIT_CARDS_AMOUNT : INIT_CARDS_AMOUNT+level)
        setModalIsOpen(false)
    }

    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: 'rgba(0,0,0,.3)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '20%',
                        backgroundColor: '#f5f0db',
                        border: '.3vw solid #FFF',
                        borderRadius: '.7vw',
                        outline: 'unset',
                        zIndex: 100,
                    }
                }
            }>
            <div className={style.container}>
                <div className={style.text}>
                    <div className={style.title}>כל הכבוד !!!</div>
                    <div className={style.subTitle}>הצלחת להתאים את כל התמונות</div>
                    <div className={style.level}>שלב: {level}</div>
                </div>
                <button onClick={handleNextLevel}>{level===3 ? "מהתחלה" : "לשלב הבא"}</button>
            </div>
        </Modal>
    )
}

export default HatamotSuccess
