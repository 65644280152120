import React from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import { AiOutlineCheck } from 'react-icons/ai';

import { colors } from '../../ContentButtons/Games/Drawing/DrawingBoard'
const ColorsPicker = ({ modalIsOpen, setModalIsOpen, 
                        penColor, setPenColor,
                        bgColor, setBgColor, choosingPenColor }) => {
    
    const handleClick = color => {
        choosingPenColor ? setPenColor(color) : setBgColor(color) 
        setModalIsOpen(false);
    } 
    
    const getColor = () => {
        if(choosingPenColor){
            return penColor;   
        }
        return bgColor;
    }

    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            onRequestClose={() => setModalIsOpen(false)}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: 'rgba(0,0,0,.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '30%',
                        backgroundColor: '#ffcc80',
                        boxShadow: "0vw .1vw .7vh rgba(0,0,0,.2)",
                        borderRadius: '1vw',
                        outline: 'unset',
                        zIndex: 100,
                    }
                }
            }>
            <div className={style.container}>
                {
                    colors.map(color => {
                        console.log("color:", color);
                        return(
                            <div className={style.color}
                                 onClick={() => handleClick(color)}
                                 key={color} 
                                 style={{backgroundColor: color,
                                         boxShadow: "0vw .1vw .7vh rgba(0,0,0,.35)",
                                         color:  getColor() === "#ffffff" ? "#000000" : "#ffffff"
                                 }}>
                                 {getColor() === color ? <div className={style.v}>✓</div> : null}
                            </div>
                        )
                    })
                }
            </div>
        </Modal>
    )
}

export default ColorsPicker
