import React, { createRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux';
import style from './style.module.css';
import play from '../../../assets/greenPlayBtn.svg'
import pause from '../../../assets/greenPauseBtn.svg'
import Song from './Song';
import { useHistory } from "react-router-dom";

const songList = [
    {id: 1, name: 'איך עוד לא המציאו?', time: '02:46'},
    {id: 2, name: 'ילדטבע', time: '02:21'},
    {id: 3, name: 'להיות אני', time: '02:22'},
    {id: 4, name: 'צב הוא צב', time: '02:31'},
    {id: 5, name: 'בטן גדולה', time: '02:21'},
    {id: 6, name: 'מנחם המנמנם', time: '03:01'},
    {id: 7, name: 'מתי כבר מגיעים?', time: '02:29'},
    {id: 8, name: 'תפילות הילדים', time: '03:16'},
    {id: 9, name: 'שטויות שגדולים אומרים', time: '02:35'},
    {id: 10, name: 'עולם חדש', time: '03:52'},
    {id: 11, name: 'לכל אחד יש לב', time: '03:03'},
    {id: 12, name: 'מתנות קטנות', time: '04:03'},
]

const Music = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);
    const [playerRefs, setPlayerRefs] = useState(Array(songList.length).fill(0).map((_, i) => React.createRef()));

    useEffect(() => {
        activeScreen !== "Music" && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Music' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'green' && dispatch({ type: "CHANGE_BG_COLOR", value: 'green' })

        return(() => {
            headerColor !== 'yellow' && dispatch({ type: "CHANGE_BG_COLOR", value: 'yellow' })
        })
    },[headerColor])

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    const setPauseOthers = () => {
        playerRefs.map((playerRef => {
            playerRef.current.pause();
        }))
    }

    return (
        <div>
            <div className={style.container}>
                <div className={style.songs}>
                {
                    songList.map((song, idx) => {return(
                        <React.Fragment key={song.id}>
                            <Song song={song} 
                                  playerRef={playerRefs[idx]}
                                  setPauseOthers={setPauseOthers}
                            />
                        </React.Fragment>
                    )})
                }
                </div>
            </div>
        </div>
    )
}

export default Music
