import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PdfTerms from './PdfTerms';
import style from './style.module.css'

const Terms = () => {
    const history = useHistory();
    const dispatch = useDispatch(); 
    const activeScreen = useSelector((state) => state.activeScreen);
    const headerColor = useSelector((state) => state.headerColor);
    
    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Terms' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Terms' })
    },[activeScreen])
    
    useEffect(() => {
        headerColor !== 'yellow' && dispatch({ type: "CHANGE_BG_COLOR", value: 'yellow' })
    },[headerColor])

    return (
        <div className={style.container}>
            <div className={style.title}>תקנון</div>
            <div className={style.content}>
                <PdfTerms />
            </div>
        </div>
    )
}

export default Terms